<template>
  <div class="container" ref="container">
    <headTab :activetab="'1'" :subtotal="getSubTotal()" :ticketsnum="stockNum" @toShopCartFromHeadTab="toShopCart" />
    <!-- 占位div -->
    <div :style="{ height: $store.state.changeHeight + 'px' }" v-if="screenWidth>768"></div>
    <!-- 内容区start -->

    <div class="main flex-space-between">

      <!-- 内容区左边 -->
      <div class="main_left" style="flex:1;">
        <div class="continue_font" @click="toExpressPass">
          CONTINUE<i class="boldFont el-icon-arrow-right el-icon--right"></i>
        </div>
        <!-- <div style="width:100%" :style="{marginTop:(screenWidth<768?'15px' :'20px')}">
          <image-banner-top style="width:100%" :boxWidth="screenWidth" />
        </div> -->
        <!-- <div style="width:95vw;min-width:360px;margin-left:-.5rem;margin-top:20px" v-else>
          <image-banner :boxWidth="screenWidth" />
        </div> -->
        <notice :type="6" />
        <h1 class="font24 boldFont title_big">TICKETS</h1>
        <p class="font16 normalFont">
          The admission ticket includes park entrance and use of all attractions
          in the park.
        </p>

        <p class="tip font16">
          Admission tickets, except for Annual Studio Passes, do not grant
          re-entry to the park after exiting.
        </p>
        <h2 class="title_2">Studio Pass (Park Entry Ticket)</h2>
        <div v-if="ticketType.length > 0" class="tickets">
          <div v-for="(item, index) in ticketType" :key="index">
            <el-card v-if="getTicketPrice(item.value)" class="box-card" style="margin-bottom: 16px;">
              <div slot="header" class="clearfix">
                <p class="font12" style="margin-bottom: 8px;">Studio Pass</p>
                <h3 class="ticketName">{{ getTicketTypeName(item.value) }}</h3>
                <!-- 1日票简介 -->
                <p class="font16" v-if="item.value === 1">
                  Not applicable for separately ticketed special events, such as Countdown Party Pass or operating hours unless stated otherwise on the pass.
                </p>
                <!-- 1.5日票简介 -->
                <p class="font16" v-if="item.value === 5">
                  Valid from 3:00 pm on the first day for two consecutive days <br><br>
                  Not applicable for separately ticketed special events, such as Countdown Party Pass or operating hours unless stated otherwise on the pass.
                </p>
                <!-- 两日票简介 -->
                <p class="font16" v-if="item.value === 2">
                  Two consecutive days for admission <br><br>
                  Not applicable for separately ticketed special events, such as Countdown Party Pass or operating hours unless stated otherwise on the pass.
                </p>
                <!-- 特殊年票简介 -->
                <!-- <p class="font16" v-if="item.value === 7">
                  This "hyper exciting" event always sells out! The one-night-only extravaganza is back again this year!
                  <br>
                  <br>
                  Event Period:19:00 on Dec. 31, 2024 - 21:00 on Jan. 1, 2025
                </p> -->
                <!-- 大门票添加红字文案 -->
                <!-- <div v-if="item.value === 7" style="padding-top:20px;color:red;white-space: pre-wrap;">
                  Note:SUPER NINTENDO WORLD™ will be closed for maintenance from 02:00 to 06:30.
                </div> -->
                <!-- <div v-else style="padding-top:20px;color:red;white-space: pre-wrap;">
                  This ticket cannot be used for the NO LIMIT! Countdown 2024 which is held from 2023-12-31 19:00 to 2024-01-01 02:00. This ticket can be used for admission after 2024-01-01 02:00. 
                </div> -->
              </div>

              <div class="card-body flex-space-between">
                <el-image style="width: 1px; height:1px;visibility:hidden;" src="" fit="fill" />
                <!-- 票计数器 -->
                <div class="counter-box">
                  <!-- 特殊年票 -->
                  <div class="flex-space-between" v-show="item.value===7">
                    <div style="padding-top:4px;">
                      <p class="font16" style="text-align:right">
                       Ticket from:
                      </p>
                      <p class="font10" style="text-align:right;">
                       All Ages
                      </p>
                    </div>
                    <div class="mr15 ticketPrice_box">
                      <span class="ticketPrice">{{
                        getTicketPrice(item.value).allYearPrice
                      }}</span>
                      <p class="font12" style="text-align:right;"> per ticket</p>
                    </div>
                    <div class="input-number">
                      <span @click="item.allYearVotes === 0 ? '' : item.allYearVotes--" class="blueFont font24 less">-</span>
                      <span class="num">{{ item.allYearVotes }}</span>
                      <span v-if="item.allYearVotes<4" @click="item.allYearVotes++" class="blueFont plus">+</span>
                      <span v-else class="blueFont plus" style="background:#eee;cursor:no-drop;">+</span>
                    </div>
                  </div>
                  <!-- 成人票 -->
                  <div class="flex-space-between" v-show="item.value!==7">
                    <div style="padding-top:4px;" v-if="item.value!==12">
                      <p class="font16" style="text-align:right;">
                        Adult ticket from:
                      </p>
                      <p class="font10" style="text-align:right;">
                        Adults(12+)
                      </p>
                    </div>
                    <div style="padding-top:4px;" v-else>
                      <p class="font16" style="text-align:right;">
                        per ticket from:
                      </p>
                      <p class="font10" style="text-align:right;">
                        All Ages
                      </p>
                    </div>
                    <div class="mr15 ticketPrice_box">
                      <span class="ticketPrice">{{
                        getTicketPrice(item.value).adultPrice
                      }}</span>
                      <p class="font12" style="text-align:right;">per ticket</p>
                    </div>
                    <div class="input-number">
                      <span @click="item.adultVotes === 0 ? '' : item.adultVotes--" class="blueFont font24 less">-</span>
                      <span class="num">{{ item.adultVotes }}</span>
                      <span v-if="item.adultVotes<4" @click="item.adultVotes++" class="blueFont plus">+</span>
                      <span v-else class="blueFont plus" style="background:#eee;cursor:no-drop;">+</span>
                    </div>
                  </div>
                  <!-- 儿童票 -->
                  <div class="flex-space-between" style="margin-top:16px;" v-show="item.value!==7 && item.value!==9 && item.value!==12">
                    <div style="padding-top:4px;">
                      <p class="font16" style="text-align:right;">
                        Child ticket from:
                      </p>
                      <p class="font10" style="text-align:right;">
                        Children(4~11)
                      </p>
                    </div>
                    <div class="mr15 ticketPrice_box">
                      <span class="ticketPrice">{{
                        getTicketPrice(item.value).childPrice
                      }}</span>
                      <p class="font12" style="text-align:right;">per ticket</p>
                      <div>
                        <!-- <apple-pay-button buttonstyle="black" type="plain" locale="zh-CN" @click="testApple"></apple-pay-button> -->
                      </div>
                    </div>
                    <div class="input-number">
                      <span @click="item.childVotes === 0 ? '' : item.childVotes--" class="blueFont less">-</span>
                      <span class="num">{{ item.childVotes }}</span>
                      <span v-if="item.childVotes<4" @click="item.childVotes++" class="blueFont plus">+</span>
                      <span v-else class="blueFont plus" style="background:#eee;cursor:no-drop;">+</span>
                    </div>
                  </div>
                  <!-- 老人 -->
                  <div class="flex-space-between" style="margin-top:16px;" v-show="item.name==='一日票'">
                    <div style="padding-top:4px;">
                      <p class="font16" style="text-align:right;">
                        Senior ticket from:
                      </p>
                      <p class="font10" style="text-align:right;">
                        Seniors(65+)
                      </p>
                    </div>
                    <div class="mr15 ticketPrice_box">
                      <span class="ticketPrice">{{
                        getTicketPrice(item.value).seniorPrice
                      }}</span>
                      <p class="font12" style="text-align:right;">per ticket</p>
                      <div>
                        <!-- <apple-pay-button buttonstyle="black" type="plain" locale="zh-CN" @click="testApple"></apple-pay-button> -->
                      </div>
                    </div>
                    <div class="input-number">
                      <span @click="
                          item.seniorVotes === 0 ? '' : item.seniorVotes--
                        " class="blueFont less">-</span>
                      <span class="num">{{ item.seniorVotes }}</span>
                      <span v-if="item.seniorVotes<4" @click="item.seniorVotes++" class="blueFont plus">+</span>
                      <span v-else class="blueFont plus" style="background:#eee;cursor:no-drop;">+</span>
                    </div>
                  </div>
                  <p style="text-align:right;padding-top:8px;margin-bottom:16px;font-size: 10px;font-weight: 400;line-height: 1.25;">
                    Prices per ticket vary by day, with tax.
                    <br>
                    Up to 4 tickets can be purchased at a time.
                    <br>
                   <span v-show="item.value===7">Same price for adults, children (4 and up), and seniors</span>
                  </p>
                </div>
              </div>
              <div class="card-foot flex-space-between">
                <el-link @click="viewDetail(item)" type="primary">View Details</el-link>
                <el-button @click="selectDate(item)" :disabled="
                      item.childVotes == 0 &&
                      item.adultVotes == 0 &&
                      item.seniorVotes == 0 &&
                      item.allYearVotes == 0
                  " :type="
                    item.adultVotes !== 0 ||
                    item.childVotes !== 0 ||
                    item.seniorVotes !== 0 ||
                    item.allYearVotes !== 0
                      ? 'primary'
                      : 'info'
                  ">SELECT A DATE</el-button>
              </div>
            </el-card>
          </div>
        </div>
          <!-- banner -->
        <div style="width:100%" :style="{marginTop:(screenWidth<768?'15px' :'20px')}">
          <image-banner-bottom style="width:100%" :boxWidth="screenWidth" />
        </div>
      </div>
      <!-- 侧边栏 -->
      <div id="aside" class="aside hidden">
        <div id="asideContent">
          <el-card class="box-card">
            <div @click="toShopCart()" slot="header" class="clearfix flex-space-between card-head" style="position: relative;">
              <p class="totalMoney font16">
                Subtotal:
                <span class="font_weight_700">¥{{ getSubTotal() || 0 | abs }}</span>
              </p>
              <span class="iconfont icon-gouwuche-copy-copy" style="position:absolute;right:0;font-size:30px;width:46px;" :style="{
                  opacity:
                    ticketsCopy.length + expressTicket.length + braceletTicket.length == 0 ? '.3' : ''
                }">
                <span class="ticketNum">{{ stockNum || 0 }}</span>
              </span>
            </div>
            <div class="card-body-item-base card-body-item-active">
              <p class="flex-space-between">
                <span class="ticketType">TICKETS</span>
                <!-- <i v-if="haveStudioPass" class="el-icon-circle-check" style="color:greenyellow;font-size:20px;" /> -->
              </p>
              <!-- tickets票列表 -->
              <div v-if="ticketsCopy.length > 0" class="ticket_list">
                <transition-group>
                  <div v-for="(item, index) in ticketsCopy" :key="index" class="ticket-item flex-space-between">
                    <div class="mr15">
                      <p>{{ getTicketTypeName(item.ticketKind) }}</p>
                      <p class="grayFont">{{ conversionPeriod(item) }}</p>
                      <p class="grayFont">
                        <span v-if="item['1'] && item['1'].num > 0 && item.ticketKind !== 12">{{ item["1"].num }}
                          {{ item["1"].num >= 2 ? "Adults" : "Adult" }}</span>
                        <span v-else-if="item['1'] && item['1'].num > 0 && item.ticketKind === 12">{{ item["1"].num }}
                          {{ item["1"].num >= 2 ? "All Ages" : "All Ages" }}</span>
                        <span v-if="item['1'].num > 0 && item['2'].num > 0">+</span>
                        <span v-if="item['2'] && item['2'].num > 0">{{ item["2"].num }}
                          {{ item["2"].num >= 2 ? "Children" : "Child" }}</span>
                        <span v-if="item['3'] && item['3'].num > 0">
                          <span v-if="(item['1'].num > 0 && item['3'].num > 0) || (item['2'].num > 0 && item['3'].num > 0) ">+</span>
                          <span v-if="item['3'] && item['3'].num > 0">{{ item["3"].num }}
                            {{ item["3"].num >= 2 ? "Seniors" : "Senior" }}</span>
                        </span>
                        <span v-if="item['4']">
                          <span v-if="(item['1'].num > 0 && item['2'].num > 0 && item['3'].num > 0) || (item['2'].num > 0 && item['3'].num > 0) || (item['1'].num > 0 && item['2'].num > 0) || (item['2'].num > 0 && item['3'].num > 0)">+</span>
                          <span v-if="item['4'] && item['4'].num > 0">{{ item["4"].num }}
                          All Ages</span>
                        </span>
                      </p>
                    </div>
                    <a @click="delTicket(index, item)" class="blueFont remove">REMOVE</a>
                  </div>
                </transition-group>
              </div>
              <span v-else class="noTicket">Buy Online and Save</span>
            </div>
            <div class="card-body-item-base">
              <p @click="toExpressPass()" class="flex-space-between" style="cursor: pointer;">
                <span class="ticketType">EXPRESS PASSES</span>
                <i v-if="expressTicket.length > 0" class="el-icon-circle-check" style="color:rgb(82, 164, 29);font-size:24px;" />
              </p>
              <span v-if="expressTicket.length <= 0" class="noTicket">Select Your Universal Express Pass</span>
            </div>
            <div class="card-body-item-base">
              <p @click="toOtions()" class="flex-space-between" style="cursor: pointer;">
                <span class="ticketType">EXTRAS</span>
                <i v-if="braceletTicket.length > 0" class="el-icon-circle-check" style="color:rgb(82, 164, 29);font-size:24px;" />
              </p>
              <span v-if="braceletTicket.length <= 0" class="noTicket">Select Your Extras</span>
            </div>
          </el-card>
          <div class="aside-btns-box">
            <el-button type="primary" @click="toExpressPass()">NEXT STEP</el-button>

          </div>
        </div>
      </div>
    </div>
    <!-- 内容区end -->
    <!-- 弹框 -->
    <date-dialog v-if="dateDialogVisible" ref="dateDialog" @openDetail="dateToDetail" @handleTicket="handleTicket" />
    <detail-dialog v-if="detailDialogVisible" ref="detailDialog" @openDateDialog="selectDate" @closeDialog="dialogClose()" />
    <loading :loading="loading" />
    <!-- 购物车删除确认框 -->
    <delTicketConfirmDialog v-if="confirmDialogVisible" ref="confirmDialog" @confirm="delTwoDayTicket" />
    <stop-Sale ref="stopSaleDialog" ></stop-Sale>
  </div>
</template>

<script>
import dateDialog from "./date-dialog.vue";
import detailDialog from "./detail-dialog.vue";
import headTab from "@/components/headTab";
import { getTicketKinds,getStopSaletime } from "@/api/index";
import { formatDate, getScrollTop, judgeMobile } from "@/utils/util";
import { mixin1, mixin2 } from "@/mixin/index";
import loading from "../../components/loading.vue";
import delTicketConfirmDialog from "../../components/delTicketConfirmDialog.vue";
import notice from "@/components/notice";
import imageBannerBottom from '../../components/imageBanner_bottom.vue';
import stopSale from '@/components/stopSaleNotice'
export default {
  mixins: [mixin1, mixin2],
  components: {
    dateDialog,
    headTab,
    detailDialog,
    loading,
    delTicketConfirmDialog,
    notice,
    imageBannerBottom,
    stopSale
  },
  name: "",
  data() {
    return {
      ticketType: [], // 票类型：一日，两日...
      dateDialogVisible: false,
      detailDialogVisible: false,
      tickets: [], //本地所有票
      orderInfo: {},
      expressTicket: [], // 快速券
      braceletTicket: [], // 手环
      haveExpressPass: false,
      loading: false,
      scrollTop: 0, // 保存滚动条位置
      confirmDialogVisible: false,
      delTicketItem: {}, // 被删除的票
      stockNum: 0,
      screenWidth: document.body.clientWidth,
      transparency: 0,
    };
  },

  async created() {
    // this.loading = true
    this.getData();
    await this.loadData();
    this.dataHandle();
    this.ticketsCopy.map((item, index) => {
      if (item.ticketKind === 3) {
        this.expressTicket.push(...this.ticketsCopy.splice(index, 1)); //提取出快速票
      }
    });
    if (JSON.parse(localStorage.getItem("orderInfo")) !== null) {
      JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList.forEach(item => {
        if (item.ticketKind === 8) {
          this.braceletTicket.push(item);
        }
      });
    }
    let arr = [];
    this.ticketsCopy.forEach(item => {
      if (
        item.ticketKind === 1 ||
        item.ticketKind === 2 ||
        item.ticketKind === 5 ||
        item.ticketKind === 7 ||
        item.ticketKind === 9 ||
        item.ticketKind === 12
      
      ) {
        arr.push(item);
      }
    });

    localStorage.setItem("oneTwoLength", arr.length); // 更新本地1日，2日，1.5日 数组长度

    this.loading = false;
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val
        this.timer = true
        let that = this
        setTimeout(function () {
          // 打印screenWidth变化的值
          that.timer = false
        }, 20)
      }
    }
  },
  mounted() {
    // let asideContent = document.getElementById("asideContent");
    // asideContent.style.top = 40 + "px";
    window.addEventListener("scroll", this.handleScroll);
    const that = this;

    window.onresize = () => (() => {
      window.screenWidth = document.body.clientWidth;
      that.screenWidth = window.screenWidth;
    })();
        // 获取停售时间
        getStopSaletime().then(res=>{
      if(localStorage.getItem('stopSale') === true){
        this.$refs.stopSaleDialog.init(res.data.data)
      }else{
        console.log('获取停售错误')
      }
    })
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScrollByIndex);
  },
  methods: {
    // 获取票类型
    async getData() {
      await getTicketKinds().then(res => {
        console.log('获取票类型',res);
        if (res.data.code === 0) {
          res.data.data.forEach(item => {
            item.adultVotes = 0;
            item.childVotes = 0;
            item.seniorVotes = 0;
            item.allYearVotes = 0;
            item.adultTicket = 1;
            item.childTicket = 2;
            item.seniorTicket = 3;
            item.allYearTicket = 4;
            // value代表票类型，一日、两日。。
            // todo 后续隐藏跨年票 7/万博票 12
            if ([1, 2, 5, 9, 12].includes(item.value)) {
             item.value!==7 ? this.ticketType.push(item):this.ticketType.unshift(item)

            }
          });
        } else {
          this.ticketType = [];
        }
        console.log(this.ticketType);
      });
    },
    // 获取本地数据
    loadData() {
      if (JSON.parse(localStorage.getItem("orderInfo")) !== null) {
        this.orderInfo = JSON.parse(localStorage.getItem("orderInfo"));
        this.tickets = this.orderInfo.orderRequestProductList;
      }
    },
    // 打开选择时间弹框
    selectDate(item) {
      console.log(item);
      this.$store.commit("SET_SCROLLTOP", getScrollTop());
      if (judgeMobile() === undefined) {
        this.dateDialogVisible = true;
        this.detailDialogVisible = false;
        this.$nextTick(() => {
          this.$refs.dateDialog.init(item);
        });
      } else {
        console.log(item);
        this.$router.push({ path: "/homeDateDialog", query: { item: encodeURI(JSON.stringify(item)) } });
      }
    },
    // 更新侧边栏
    handleTicket(val) {
      if (val) {
        this.$router.go(0);
      }
      if (JSON.parse(localStorage.getItem("orderInfo"))) {
        this.orderInfo = JSON.parse(localStorage.getItem("orderInfo"));
        this.tickets = this.orderInfo.orderRequestProductList;
        this.dataHandle();
      }
      this.detailDialogVisible = false;
    },
    // 查看详情
    viewDetail(item) {
      this.scrollTop = document.documentElement.scrollTop;
      if (judgeMobile() === undefined) {
        this.detailDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.detailDialog.init(item);
        });
      } else {
        console.log(item);
        this.$router.push({ path: "/homedetaildialog", query: { item: encodeURI(JSON.stringify(item)) } });
      }
      this.$nextTick(() => {
        this.$refs.detailDialog.init(item);
      });
    },
    // 详情弹框让页面回到打开弹框之前的位置
    dialogClose() {
      document.documentElement.scrollTop = this.scrollTop;
    },
    // 获取总价
    getSubTotal() {
      this.ticketsCopy.map((item, index) => {
        if (
          item.ticketKind !== 1 &&
          item.ticketKind !== 2 &&
          item.ticketKind !== 5 &&
          item.ticketKind !== 7 &&
          item.ticketKind !== 9 &&
          item.ticketKind !== 12
        ) {
          this.expressTicket.push(...this.ticketsCopy.splice(index, 1)); //提取出快速票
        }
      });
      let total = 0;
      let expressTicketArr = [];
      let onetwolength = localStorage.getItem("oneTwoLength"); // 更新本地1日，2日，1.5日 数组长度

      if (JSON.parse(localStorage.getItem("orderInfo"))) {
        JSON.parse(
          localStorage.getItem("orderInfo")
        ).orderRequestProductList.forEach(item => {

          if (item.ticketKind === 3) {
            expressTicketArr.push(item); //提取出快速票
          }
        });
      }

      if (this.ticketsCopy.length > 0) {
        this.ticketsCopy.map(item => {
          console.log(item['4']);
          if (item["1"] && item["2"] && item["3"]) {
            total +=
              item["1"].num * item["1"].price + item["2"].num * item["2"].price + item["3"].num * item["3"].price
          } else if (item["1"] && item["2"]) {
            total +=
              item["1"].num * item["1"].price + item["2"].num * item["2"].price
          } else if (item["1"] && item["2"] && item["3"] && item["4"]) {
            total +=
              item["1"].num * item["1"].price + item["2"].num * item["2"].price + item["3"].num * item["3"].price + item["4"].num * item["4"].price
          } 
         if(item["4"]){
            console.log('尽量');
             total += item["4"].num * item["4"].price
          }
        });
      }
      if (expressTicketArr.length > 0) {
        expressTicketArr.forEach(item => {
          total += item.num * item.price;
        });
      }
      if (this.braceletTicket.length > 0) {
        this.braceletTicket.map(item => {
          total += item.num * item.price;
        });
      }
      this.stockNum =
        parseInt(onetwolength) +
        parseInt(expressTicketArr.length) +
        parseInt(this.braceletTicket.length);
        console.log(this.stockNum);
      return total;
    },
    dateToDetail(item) {
      this.viewDetail(item);
    },
    toExpressPass() {
      let arr = [];
      this.ticketsCopy.forEach(item => {
        if (
          item.ticketKind === 1 ||
          item.ticketKind === 2 ||
          item.ticketKind === 5 ||
          item.ticketKind === 7 ||
          item.ticketKind === 9 ||
          item.ticketKind === 12
        ) {
          arr.push(item);
        }
      });

      localStorage.setItem("oneTwoLength", arr.length); // 更新本地1日，2日，1.5日 数组长度
      this.$router.push({ path: "/expressPass" });
    },
    // 删除票
    delTicket(index, item) {
      const ticketKind = item.ticketKind;
      this.delTicketItem = item;
      // 如果被删除的是1.5/2日票，且购物车还有其他票的时候弹框提示
      if (
        [2, 5].includes(ticketKind) &&
        (this.ticketsCopy.length > 1 || this.expressTicket.length > 0 || this.braceletTicket.length>0)
      ) {
        this.confirmDialogVisible = true;
        this.$nextTick(() => {
          this.$refs.confirmDialog.init(item);
        });
        // localStorage.setItem('orderInfo', '')
      } else {
        this.ticketsCopy.splice(index, 1);
        this.tickets = [];
        this.ticketsCopy.map(item => {
          this.tickets.push(...this.splitObj(item));
        });
        this.tickets.push(...this.expressTicket);
        this.orderInfo.orderRequestProductList = this.tickets;
        localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
        let arr = [];
        this.ticketsCopy.forEach(item => {
          if (
            item.ticketKind === 1 ||
            item.ticketKind === 2 ||
            item.ticketKind === 5 ||
            item.ticketKind === 7 ||
            item.ticketKind === 9 ||
            item.ticketKind === 12
          ) {
            arr.push(item);
          }
        });
        localStorage.setItem("oneTwoLength", arr.length); // 更新本地1日，2日，1.5日 数组长度
      }
      this.getSubTotal();
      this.$store.commit("SET_SHOPCART"); // 更新vuex的数据
      this.$store.commit("SET_TICKET_DATE"); // 更新票的可选时间
    },
    // 删除1.5/2日票
    delTwoDayTicket() {
      this.confirmDialogVisible = false;
      this.tickets = [];
      this.orderInfo.orderRequestProductList = this.tickets;
      localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo)); // 更新本地数据
      this.$store.commit("SET_SHOPCART"); // 更新vuex的数据
      this.$store.commit("SET_TICKET_DATE"); // 更新票的可选时间
      this.$router.go(0);
    },

    // 设置有限期范围
    conversionPeriod(item) {
      var startTime = item.travelDate;
      // var endTime = ''
      // endTime = getDateStr(startTime, 90)
      startTime = formatDate(startTime);
      // endTime = formatDate(endTime)
      // return `Valid any same price day from ${startTime} ~ ${endTime} `
      return startTime;
    },
    // 获取当前环境的票价格
    getTicketPrice(type) {
      let option = process.env.VUE_APP_TICKETPRICE;
      option = eval("(" + option + ")");
      return option[type];
    },
    getTicketTypeName(value) {
      const options = {
        1: "1 Day Studio Pass",
        2: "2 Day Studio Pass",
        5: "1.5 Day Studio Pass",
        7: "NO LIMIT! Countdown 2025 PARTY PASS",
        9: "1 Day Studio Pass + Have Fun in Kansai",
        12: "1 Day Studio Pass + Expo 2025 Osaka Admission ticket",
      };
      return options[value];
    },
    toShopCart() {
      let arr = [];
      this.ticketsCopy.forEach(item => {
        if (
          item.ticketKind === 1 ||
          item.ticketKind === 2 ||
          item.ticketKind === 5 ||
          item.ticketKind === 7 ||
          item.ticketKind === 9 ||
          item.ticketKind === 12
        ) {
          arr.push(item);
        }
      });

      localStorage.setItem("oneTwoLength", arr.length); // 更新本地1日，2日，1.5日 数组长度
      this.$router.push({ path: "/shopCart" });
    },
    toOtions() {
      let arr = [];
      this.ticketsCopy.forEach(item => {
        if (
          item.ticketKind === 1 ||
          item.ticketKind === 2 ||
          item.ticketKind === 5 ||
          item.ticketKind === 7 ||
          item.ticketKind === 9 ||
          item.ticketKind === 12
        ) {
          arr.push(item);
        }
      });

      localStorage.setItem("oneTwoLength", arr.length); // 更新本地1日，2日，1.5日 数组长度
      this.$router.push({ path: "/options" });
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  position: relative;
  .main {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    .aside {
      margin-left: 30px;
      position: relative;
      #asideContent {
        position: relative;
        top: 40px;
        width: 240px;
        // opacity: 0;
      }
      .card-body-item-base {
        padding: 16px;
        padding-bottom: 24px;
        max-height: 300px;
        overflow: auto;
        &:not(:last-child) {
          border-bottom: 1px solid #ddd;
        }
      }
      .card-body-item-active {
        border-left: 2px solid #0959c2;
        background-color: #f9fcff;
      }
    }
  }
  .row {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
  }
}
.title_big {
  margin: 19.92px 0;
}
.title_2 {
  font-size: 20px;
  line-height: 1.25;
  font-weight: 900;
  margin: 0;
  color: #333;
  margin-bottom: 24px;
}
::v-deep .tickets {
  .el-card__header {
    padding: 32px 24px 0;
    border-bottom: 0;
    // margin-bottom: 24px;
  }
  .el-card__body {
    padding: 20px 0;
    // padding-top: 0;
  }
}
.noTicket {
  font-size: 10px;
  line-height: 1.25;
  font-weight: 400;
  margin: 8px 0;
}
.ticketName {
  font-size: 20px;
  line-height: 1.25;
  font-weight: 700;
  color: #333;
  word-break: break-word;
  margin: 0;
}
.ticketPrice {
  margin-left: 8px;
  font-size: 20px;
  @media (max-width: 1200px) {
    font-size: 18px;
  }
  line-height: 1.5;
  font-weight: 700;
  color: #0a0a0a;
}
.continue_font {
  display: none;
}
.tip {
  padding: 15px;
  background: -webkit-linear-gradient(to bottom, #1d3586, #3555a3);
  background: -o-linear-gradient(to bottom, #1d3586, #3555a3);
  background: -moz-linear-gradient(to bottom, #1d3586, #3555a3);
  background: linear-gradient(to bottom, #1d3586, #3555a3);
  color: white;
  margin: 15px 0 20px 0;
  font-weight: 500;
}
.card-foot {
  border-top: 1px solid gainsboro;
  padding: 20px 24px 0;
  font-size: 14px;
  /deep/ .el-button--primary {
    border-radius: 13px;
    background-color: #036ce2;
    font-weight: 700;
    height: 46px;
  }
  .el-button--info {
    border-radius: 13px;
    font-weight: 700;
    height: 46px;
  }
  .el-link {
    font-weight: 500;
    font-size: 16px;
    color: rgb(3, 108, 226);
  }
}
.ticket-img {
  width: 113px;
  height: 75px;
}
/**票计数器start**/
.input-number {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 24px;
  min-width: 88px;
  .num {
    color: #036ce2;
    display: inline-block;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 24px;
    font-weight: 900;
    line-height: 20px;
    text-align: center;
    width: 40px;
    margin: 0;
  }
  .plus {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid gainsboro;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
  }
  .less {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid gainsboro;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
  }
}
/**票计数器end**/
.ticketPrice_box {
  flex: 1;
  text-align: right;
}
.el-card {
  border-radius: 15px;
}
.counter-box {
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  // margin-top: 15px;
}
.totalMoney {
  // margin-right: 40px;
  vertical-align: super;
}
.ticketType {
  font-size: 12px;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.ticket-item {
  font-size: 12px;
  margin-top: 16px;
  color: #333333;
}
.card-head {
  align-items: center;
  .ticketNum {
    position: absolute;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    background-color: rgb(22, 43, 117);
    color: white;
    text-align: center;
    font-size: 12px;
    top: 6px;
    right: 15px;
  }
}
.aside-btns-box {
  text-align: center;
  margin-top: 24px;
  .el-button--primary {
    background-color: #036ce2;
    border-radius: 13px;
    font-weight: 700;
    height: 31px;
    line-height: 31px;
    padding: 0 15px;
  }
}
.flex-end {
  align-items: flex-end;
}
/* 删除产品时动画过渡start*/
.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.v-enter-active,
.v-leave-active {
  transition: all 1s ease;
}
/* 删除产品时动画过渡end*/
.remove {
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
  min-width: 55px;
}
.normalFont {
  font-weight: normal;
}
h1 {
  color: #333;
}
::v-deep.el-icon-shopping-cart-2:before {
  font-size: 30px;
}
@media (max-width: 1200px) {
  .container {
    width: 100%;
    .main {
      width: 100%;
      padding: 0 0.9375rem;
      box-sizing: border-box;
    }
  }
}
@media (max-width: 780px) {
  .hidden {
    display: none;
  }
  .container .main {
    // padding-top: 120px;
  }
  .continue_font {
    font-size: 12px;
    display: block;
    text-align: right;
    color: #036ce2;
    font-weight: bold;
    cursor: pointer;
    // margin-top: 20px;
  }
  .card-foot {
    display: flex;
    flex-direction: column-reverse;
    padding: 16px 16px 0;
    .el-link {
      padding-top: 16px;
    }
  }
  .counter-box {
    padding-right: 16px;
  }
  .input-number {
    min-width: 90px;
    .less {
      font-weight: 500;
    }
    .plus {
      font-weight: 500;
    }
  }
  ::v-deep .el-icon-arrow-right:before {
    font-size: 24px;
    vertical-align: middle;
  }
  .ticketPrice_box {
    flex: 1;
    text-align: right;
  }
}
::v-deep.el-card__header {
  border-bottom: 0;
}
::v-deep.aside .el-card__header {
  background-color: #036ce2;
  color: white;
  padding: 10px 16px;
}
::v-deep.aside .el-card__body {
  padding: 0;
}
::v-deep .el-link.is-underline:hover:after {
  border-bottom: none;
}
@media print, screen and (min-width: 40em) {
  .container {
    .row {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem;
    }
  }
}
</style>
